@mixin page-heading-one {
  @apply font-black font-display leading-tight text-5xl tracking-normal;

  @screen lg {
    @apply text-8xl;
  }
}

@mixin page-heading-two {
  @apply font-black font-display leading-tight text-4xl tracking-normal;

  @screen lg {
    @apply text-7xl;
  }
}

@mixin page-heading-three {
  @apply font-black font-display leading-tight text-3xl tracking-normal;

  @screen lg {
    @apply text-6xl;
  }
}

@mixin page-heading-four {
  @apply font-bold font-display leading-tight text-lg tracking-normal;

  @screen lg {
    @apply text-2xl;
  }
}

@mixin page-heading-five {
  @apply font-bold font-display leading-tight text-lg tracking-normal;
}

@mixin page-heading-six {
  @apply font-bold font-display leading-tight text-md tracking-normal;
}
