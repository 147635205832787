.site-message-content {
  @apply mx-10;

  strong,
  b {
    @apply font-bold;
  }

  em,
  i {
    @apply italic;
  }

  a, u {
    @apply underline;
  }
}