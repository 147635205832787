.outer {
  @apply absolute h-full max-h-full inset-0 m-auto max-w-2xl z-50;

  @screen md {
    @apply rounded-lg shadow-2xl;
  }

  @screen lg {
    @apply max-w-4xl;
  }
}

.mobile-menu {
  @apply absolute h-full inset-0 w-full z-50;
}

.alert {
  @apply absolute h-full max-h-full inset-0 m-auto z-50 w-full;

  @screen md {
    @apply rounded-lg shadow-2xl;
    max-width: 30rem;
  }

  .inner {
    @apply px-4 pb-6;
    height: calc(100% - 60px);

    @screen md {
      @apply px-12 pb-8;
      height: calc(100% - 68px);
    }
  }
}

.medium {
  @apply absolute h-full max-h-full inset-0 m-auto z-50 w-full;

  @screen md {
    @apply rounded-lg shadow-2xl;
    max-width: 40rem;
  }

  .inner {
    @apply px-4 pb-6;
    height: calc(100% - 60px);

    @screen md {
      @apply px-12 pb-8;
      height: calc(100% - 68px);
    }
  }
}

.inner {
  @apply px-4 pb-6 overflow-y-scroll;
  height: calc(100% - 70px);

  &::-webkit-scrollbar {
    background: white;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0075bf;
    border-radius: 5px;
  }

  @screen md {
    @apply px-12 pb-12;
    height: calc(100% - 78px);
  }
}
