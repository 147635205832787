.buttonOneTrust {
  border: none !important;
  padding: 0 !important;
  font-size: 1rem !important;
  color: black !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;

  &:hover {
    @apply text-french-blue #{!important};
    background-color: inherit !important;
  }
}
