@import '../../../styles/typography-mixins';

.rich-text {
  h1 {
    @include page-heading-one;
    @apply mb-4;
  }

  h2 {
    @include page-heading-two;
    @apply mb-4;
  }

  h3 {
    @include page-heading-three;
    @apply mb-4;
  }

  h4 {
    @include page-heading-four;
    @apply mb-4;
  }

  h5 {
    @include page-heading-five;
    @apply mb-3;
  }

  h6 {
    @include page-heading-six;
    @apply mb-2;
  }

  p {
    @apply mb-8 text-grey-600;
  }

  ul {
    @apply mb-8 pl-5 list-disc text-grey-600;
  }

  ol {
    @apply mb-8 pl-5 list-decimal text-grey-600;
  }

  &-spacing-half {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-2;
    }

    p,
    ul,
    ol {
      @apply mb-4;
    }
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child {
    @apply mb-0;
  }

  a {
    @apply text-french-blue hover:underline;
  }

  strong,
  b {
    @apply font-bold;
  }

  em,
  i {
    @apply italic;
  }

  u {
    @apply underline;
  }

  center {
    @apply text-center;
  }
}
